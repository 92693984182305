$(document).ready(function() {
    // myFns();

});

function mySideBarInitFn() {
    // console.log('AppIni');
    App.init();
    const ps = new PerfectScrollbar('.menu-categories', {
        wheelSpeed: .5,
        swipeEasing: !0,
        minScrollbarLength: 40,
        maxScrollbarLength: 300
    });
}